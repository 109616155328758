/* eslint-disable no-redeclare */
<template>
    <el-row :gutter="24" class="content">
        <!-- banner 介绍 -->
        <el-col :span="24">
            <div class="grid-content bg-purple-dark">
                <el-image style="width: 100%; height: 500px" src="http://test.mtxcjy.com/static/image/index/index.png" fit="fill"></el-image>
                <div class="banner-content">
                    <h2>{{ title }}</h2>
                    <span>{{ about }}</span>
                    <br>
                    <p>{{ introduce1 }}</p>
                    <p>{{ introduce2 }}</p>
                </div>
            </div>
        </el-col>
        <!-- 教育领域 -->
        <el-col :span="24">
            <div class="education-field">
            <div class="education-field-content">
                <h1>{{ educationfieldH1 }}</h1>
                <span>{{ educationfieldSpan }}</span>
                <p>{{ educationfieldP }}</p>
                <ul>
                   <li  class="liStyle" :style="item.styles"  :key="index" v-for="(item,index) in educationfieldList">
                       <h6>{{ item.h6 }}</h6>
                       <p>{{ item.p }}</p>
                   </li>
                </ul>
            </div>
        </div>
        </el-col>
        <!-- 荣誉 -->
        <!-- <el-col :span="24">
            <div class="honor">
            <div class="honor-header">
                <h1>满天星辰荣誉</h1>
                <span>OFFCN HONOR</span>
            </div>
            <div class="honor-carousel">
                <div class="swiper-container">
                    <swiper :options="swiperOptions">
                        <swiper-slide class="swiper-slide swiper-fix" :key="index" v-for="(item,index) in swiperList">
                                <span class="span-fix">{{ item.swiperSpan }}</span>
                                <p class="p-fix">{{ item.swiperP }}</p>
                                <img  :src="item.swiperImg" :alt="item.swiperSpan" >   
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
        </el-col> -->
        <!-- 讲师介绍 -->
        <el-col :span="24">
            <div class="honor-teacher">
                <div class="teacher-header">
                    <h1>优秀教师</h1>            
                </div>
                <div class="honor-carousel-teacher">
                    <div class="mySwiperTeacher">
                            <swiper :options="swiperOptions1">
                                    <swiper-slide class="swiper-slide"  :key="index" v-for="(item,index) in honorTeacherList">
                                         <div class="teacher-box">                        
                                        <img :src="item.honorTeacherImg" alt="图片被怪兽吃掉啦" >
                                        <h3>{{ item.honorTeacherName }}</h3>
                                        <p>{{ item.honorTeacherIntroduce }}</p>                    
                                    </div>
                                </swiper-slide>
                                
                            </swiper>
                    </div>                
                </div>
        </div>
        </el-col>
    </el-row>
</template>

<style scoped>



.banner{
    width: 1920px;
    height: 500px;
    background: url("http://test.mtxcjy.com/static/image/index/index.png") no-repeat;
    /* background: url("http://localhost:8086/static/image/index/index.png") no-repeat; */
}

.swiper-fix{
    border: 1px solid #ffffff;
    box-shadow: 5px 2px 10px #a3a1a1;
    background: rgba(255,255,255, 0.20);
    backdrop-filter: blur(3px);
    border-radius: 15px;
}

.span-fix{
    display: block;
    margin-top: 10px;
}

.p-fix{
    font-size: 13px;
    width: 400px;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.banner-content{
    margin: -19% 15%;
    width: 1200px;
    max-width: 1300px;
    /* min-height: 280px; */
    text-align: center;
    position: absolute;
    background: rgba(255,255,255, 0.20);
    backdrop-filter: blur(8px);
    border-radius: 25px;
}

.banner-content h2{
    font: 30px/34px"Microsoft YaHei";
    color: #030303;
}

.banner-content > p {
    font: 15px/19px "";
    text-indent: 1em;
    padding: 8px;
}

.education-field {
    height: 500px;
    /* border: 1px solid red; */
    text-align: center;
}

.education-field > .education-field-content {
    position: relative;
    margin: 0 auto;
    width: 1400px;
}

.education-field .education-field-content h1{
    color: #141a1c;
    font-size: 40px;
    margin-top: 15px;
}

.education-field .education-field-content span{
    font-size: 30px;
}

.education-field .education-field-content p{
    color: red;
    margin-top: 15px;
}

.education-field .education-field-content ul {
    margin-top: 40px;
}

.education-field .education-field-content ul li{
    list-style: none;
    float: left;
    /* overflow: hidden; */
}

.education-field .education-field-content ul .liStyle{
    border-width: 1px;
    border-color: rgb(53, 53, 53);
    border-style: solid;
    /* width: 16.1%; */
    width: 210px;
    height: 196px;
    margin-left: 40px;

}

.education-field .education-field-content ul .liStyle h6{
    font: bold 48px/54px"Microsoft YaHei";
    color: #353535;
    background: #fff;
    /* position: relative;
    left: 3%;
    top: -29px;
    width: 50px; */
}

.honor{
    /* width: 1900px; */
    /* height: 500px; */
    /* border: 1px solid red; */
    text-align: center;
    /* background: url(http://test.mtxcjy.com/static/image/index/background.jpg); */
}

.honor-carousel{
    position: relative;
    margin: 0 auto;
    margin-top: 20px;
    /* border: 1px solid red; */
}

.honor-carousel img{
    width: 400px;
    height: 280px;
}

/* 讲师 */
.honor-teacher{
    /* max-width: 1920px !important; */
    /* height: 500px; */
    /* 蓝色 */
    /* background: #f1f8ff; */
    /* 灰色 */
    /* background: #f2f2f2; */
    /* background: url(http://test.mtxcjy.com/static/image/index/sky.jpg) no-repeat 100%; */
    background: url(https://s1.ax1x.com/2022/03/31/qWkQDU.png
    ) no-repeat 100%;
    text-align: center;
    /* 把多余出来的隐藏掉。 */
    /* overflow: hidden; */
}

.teacher{
    height: 500px; 
    border: 1px solid red;
}

.teacher-header{
    padding: 15px;
}

.honor-carousel-teacher{
    position: relative;
    height: auto;
    overflow: hidden;
    
}

.honor-carousel-teacher .teacher-box{
    position: relative;
    width: 1000px;
    height: 500px;
    /* border: 1px solid red; */
    margin: 0 auto;
    /* margin-top: 15px; */
}

.honor-carousel-teacher img{
    /* width: 400px; */
    height: 400px;
    float: left;
    overflow: hidden;
    margin-right: 45px;
    border: 2px solid #315271;
    border-radius: 15px;
    /* padding: 35px; */
}

.honor-carousel-teacher h3{
    position: absolute;
    top: 88%;
    left: 13%;
}

.honor-carousel-teacher p {
    font: 22px/40px"Microsoft YaHei";
    color: #000000;
    /* margin-top: -13px; */
    /* width: 600px; */
    /* float: right; */
}


 


</style>

<script>
// eslint-disable-next-line no-unused-vars
// import Swiper from "swiper";
export default {
    components: { },
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'index',
    data() {
        return {
            // Swiper 方法
            swiperOptions:{
                slidesPerView: 3,
                spaceBetween: 30,
                parallax: true,
                centeredSlides: true,
                loop: true,
                autoplay : true,
                pagination: {
                    el: '.swiper-container',
                    clickable: true,
                },
            },
            // Swiper 方法
            swiperOptions1:{
                speed: 2000,
                slidesPerView: 1,
                spaceBetween: 30,
                parallax: true,
                centeredSlides: true,
                autoplay: true,
                loop: true,
                // autoplay : true, 
            },
            title: '星辰互联教育',
            about: 'ABOUT US',
            introduce1: '星辰互联教育是大型的多品类职业教育机构。 公司勇担时代使命，持续创造新的市场，以友善利他之心服务社会。 面向广大知识型人群，公司主营业务横跨招录考试培训、学历提升和职业能力培训等3大板块， 提供超过100个品类的综合职业就业培训服务。 公司在全国1859个直营网点展开经营， 深度覆盖 319个地级市，并正在快速向数千个县城和高校扩张。 星辰互联教育主要服务于18岁—45岁的大学生、大学毕业生和各类职业专才等知识型就业人群。 这个巨大群体广泛分布于全国各地、各级城镇和各行各业 ， 就业和职业能力提升 是他们的两大核心诉求。',
            // introduce2 : '原院校成教中心服务社会在职走读学生。 随着国家政策趋势， 2012-2013年在国家的安排下，脱离院校成教中心， 成为教育局备案独立教学服务的单位。 （大东华教育培训中心）15年开展线上+线下授课 教学与高校合作，研发独家对接院校后台的，互联网APP学习平台，三合一科学服务取证。 2015年受邀成为广东省高教大厦成教中心直属招生点，入驻广州市越秀区农林下路723号广东高教大厦附楼212室进行办公。 2015年为发展全国成人学历教育，逐步发展西南（四川、重庆）江浙（江苏、浙江）地区线上教育，2016年被江苏省评为优质互联网教育单位。蝉联三届“广东省优质教学培训中心”秉承着传统教育的优良传统， 以提高社会在职人员综合素质，解决国家民生文化为己任，为学生搭建提升自我能力拓展人脉的学习交流平台， 每个季度会举办线下学习交流会，每年举办开学典礼和毕业典礼，以学生自愿参加为原则。为国家以及社会输送更多优质学生。20年线下授课经验，第一批线上授课教育单位，毕业生人数突破10万人， 在读人数5万余人，全国互联网优质线上授课模范教学点。',
            educationfieldH1: '专注在线教育领域',
            educationfieldSpan: '致力成为行业楷模',
            // educationfieldP: '星辰互联教育原传统线下教育学习平台，最初服务于社会走读夜大教学模式。伴随社会互联网的发展，于2015年携手各大高校转型为互联网线上授课学习平台，是一家以在线教育为基础的教育培训单位。单位秉承着为帮助更多扎根在城市奋斗的年轻人通过学习改变命运为己任，多年来携手国内多所知名院校共同打造国内领先的互联网教学平台。以提供更为便捷、高效的学习方式，确保获得学习成果为服务宗旨，一直致力于打造国内领先且让城市奋斗者终生受益的学习平台。',
            educationfieldList:[
                {
                    styles: 'background:url(http://test.mtxcjy.com/static/image/index/map.png) no-repeat 45% 80%',
                    h6: '31',
                    p: '全国31个省份'
                },
                {
                    styles: 'background:url(http://test.mtxcjy.com/static/image/index/city.png) no-repeat 45% 80%',
                    h6: '319',
                    p: '319个地市'
                },
                {
                    styles: 'background:url(http://test.mtxcjy.com/static/image/index/shop.png) no-repeat 45% 80%',
                    h6: '1859',
                    p: '1859家直营分部和学习中心'
                },
                {
                    styles: 'background:url(http://test.mtxcjy.com/static/image/index/project.png) no-repeat 45% 80%',
                    h6: '22000+',
                    p: '专职教研团队人员'
                },
                {
                    styles: 'background:url(http://test.mtxcjy.com/static/image/index/pople.png) no-repeat 45% 80%',
                    h6: '45000+',
                    p: '超45000名员工'                       
                }
            ],
            swiperList:[
                {
                    swiperSpan:'星辰互联教育获评2015年度深圳最具影响力品牌',
                    swiperP:'2015年12月30日，星辰互联教育由传统线下面授教育转型为互联网线上教育模式 获评“2020年深圳互联网线上优质培训单位',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/2015%E6%9C%80%E4%BD%B3%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4%E4%B8%8E%E4%BC%98%E8%B4%A8%E5%8D%95%E4%BD%8D.png'
                },
                {
                    swiperSpan:'星辰互联教育荣获2016年年度优质优秀教学点',
                    swiperP:'教学点 2016年7月，星辰互联教育被广东省各大合作高校评为“优秀教学点” 优秀自学考试社会助学教点。',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/%E5%B9%BF%E4%B8%9C%E7%9C%81%E9%AB%98%E6%A0%A1%E4%BC%98%E7%A7%80%E6%95%99%E5%AD%A6%E7%82%B9.jpg'
                },
                {
                    swiperSpan:'星辰互联教育荣获江苏省“优质互联网教育单位”',
                    swiperP:'2016年10月在江苏省互联网教育研讨峰会，星辰互联教育脱颖而出 一举获得江苏省各大高校领导认可，被评为江苏省“优质互联网教育单位” 为江苏省社会型人才发展培养持续做贡献',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/%E6%B1%9F%E8%8B%8F%E7%9C%81%E4%BC%98%E8%B4%A8%E4%BA%92%E8%81%94%E7%BD%91%E6%95%99%E8%82%B2%E5%8D%95%E4%BD%8D.png'
                },
                {
                    swiperSpan:'星辰互联教育荣获四川省“新实力教育单位”',
                    swiperP:'2017年12月，随着全国市场的开展，星辰互联教育于四川省招生授课2年，获得四川省高校青睐，获评为“新实力教育单位”。 为西南地区文化发展持续贡献。',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/2017%E5%B9%B4%E5%9B%9B%E5%B7%9D%E7%9C%81%E9%AB%98%E6%A0%A1%E5%A7%94%E5%91%98%E4%BC%9A%E2%80%9C%E6%96%B0%E5%AE%9E%E5%8A%9B%E6%95%99%E8%82%B2%E5%8D%95%E4%BD%8D%E2%80%9D.jpg'
                },
                {
                    swiperSpan:'星辰互联教育蝉联三年广东“优质互联网教育单位”',
                    swiperP:'2018年7月，由广东省各大高校组织的教育培训研讨会中，星辰互联教育秉承着“师者，传道授业解惑”的理念，转型为互联网教育培训模式后，兢兢业业即2016、2017年后再次获得广东省“优质互联网教育单位”成为广东省首家蝉联三次获得此殊荣的培训单位。',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/2018%E5%B9%B4%E5%B9%BF%E4%B8%9C%E7%9C%81%E2%80%9C%E4%BC%98%E8%B4%A8%E4%BA%92%E8%81%94%E7%BD%91%E6%95%99%E8%82%B2%E5%8D%95%E4%BD%8D%E2%80%9D.jpg'
                },{
                    swiperSpan:'星辰互联教育广东省年度招生人数突破1万人',
                    swiperP:'2019年1月，经教务中心统计，星辰互联教育2018年在广东省年度招生人数共计1万2千余人，首次以省级为单位招生人数突破万。从2013年的线下走读培训，到2015年的线上教育培训，星辰互联教育不忘初心，致力于服务社会在职人员，成就他人，成就自己。“授人以鱼不如授人以渔”',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/2018%E5%B9%B4%E5%BA%A6%E6%8B%9B%E7%94%9F%E4%BA%BA%E6%95%B0%E7%AA%81%E7%A0%B4%E4%B8%80%E4%B8%87%E4%BA%BA%E6%AC%A1%E2%80%9C%E6%8E%88%E4%BA%BA%E4%BB%A5%E9%B1%BC%E4%B8%8D%E5%A6%82%E6%8E%88%E4%BA%BA%E4%BB%A5%E6%B8%94%E2%80%9D.jpg'
                },{
                    swiperSpan:'星辰互联教育荣誉百度营销“最佳合作伙伴”',
                    swiperP:'2019年6月，伴随着互联网的飞速发展，星辰互联教育与百度公司合作，开展互联网流量推广模式，帮助更多的社会在职人员网上可了解学历课程，荣获百度营销2019年度教育行业“最佳合作伙伴”',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/2019%E5%B9%B4%E7%99%BE%E5%BA%A6%E4%BA%92%E8%81%94%E7%BD%91%E8%90%A5%E9%94%80%E2%80%9C%E6%9C%80%E4%BD%B3%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4%E2%80%9D.jpg'
                },{
                    swiperSpan:'星辰互联教育荣获广东省“优秀人才培养基地”',
                    swiperP:'2019年12月星辰互联教育随着国家职业技能教育的发展，开展如会计、电工、计算机网络管理员等技术性培训课程，为社会发展输送高技能人才。在2019年广东省职业技能人才培养大会中，获评广东省“优秀人才培养基地”',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/2019%E5%B9%B4%E5%B9%BF%E4%B8%9C%E7%9C%81%E9%AB%98%E6%A0%A1%E4%BA%BA%E6%89%8D%E5%B0%B1%E4%B8%9A%E5%9F%BA%E5%9C%B0%E2%80%9C%E4%BC%98%E7%A7%80%E4%BA%BA%E6%89%8D%E5%9F%B9%E5%85%BB%E5%9F%BA%E5%9C%B0%E2%80%9D.jpg'
                },{
                    swiperSpan:'星辰互联教育荣获深圳市“良心教育培训单位”',
                    swiperP:'2020年国家受疫情影响，全国经济发展下滑，社会在职人员收入低微，星辰互联教育在疫情阶段启动“疫情补贴报考学历”为800余经济困难学生，免费报考学历，获得“良心教育培训单位”称号。教育因人而异，每个学生都拥有学习的权利，我们更多的需要鼓励学生读书改变命运。',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/2020%E5%B9%B4%E6%B7%B1%E5%9C%B3%E5%B8%82%E7%A4%BE%E4%BC%9A%E4%BF%9D%E9%9A%9C%E2%80%9C%E8%89%AF%E5%BF%83%E6%95%99%E8%82%B2%E5%9F%B9%E8%AE%AD%E5%8D%95%E4%BD%8D%E2%80%9D.jpg'
                },{
                    swiperSpan:'星辰互联教育开启“残障人士爱心辅导班”',
                    swiperP:'2020年12月星辰互联教育总经理梁新达先生，星辰互联教育决定为残疾智障身体有缺陷的学生，提供学习的机会。凡是具有国家残疾证，贫困证、智力缺陷证等学生，均可以再星辰互联教育单位，免费提升学历。',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/2020%E5%B9%B4%E5%BA%A6%E5%BC%80%E5%90%AF%E2%80%9C%E6%AE%8B%E9%9A%9C%E4%BA%BA%E5%A3%AB%E7%88%B1%E5%BF%83%E8%BE%85%E5%AF%BC%E7%8F%AD%E2%80%9D.jpg'
                },{
                    swiperSpan:'星辰互联教育广东省“十佳教育培训单位”',
                    swiperP:'2021年8月，经教务中心统计，星辰互联教育至办学以来兢兢业业服务社会在职人员，辛苦耕耘换回来硕果累累，毕业生人数突破8万人，风雨十几载，感恩所有学生一路前行，星辰互联教育将秉承初衷，脚踏实地，为国家为社会输送更多高学历高素质人才，为学生提供更加优质的教学服务平台，让所有学生可以科学简单取证的同时，提升自己的能力以及职场竞争力。',
                    swiperImg:'http://test.mtxcjy.com/static/image/honor/2021%E5%B9%B4%E5%BA%A6%E5%B9%BF%E4%B8%9C%E7%9C%81%E2%80%9C%E5%8D%81%E4%BD%B3%E6%95%99%E8%82%B2%E5%9F%B9%E8%AE%AD%E5%8D%95%E4%BD%8D%E2%80%9D.jpg'
                },{
                    swiperSpan:'未来',
                    swiperP:'教育新时代已经来临，传奇仍在继续。。。。。',
                    swiperImg:''
                }

            ],
            honorTeacherList:[
                {
                    honorTeacherImg:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F018afd5ebe582da8012072006d041c.jpg%402o.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1663934781&t=29a001f0644940c27c0b72745dd4cb8f',
                    // honorTeacherImg:'',
                    honorTeacherIntroduce:'中共党员，华中师范大学中共党史专业博士，副教授。【研究领域】 党史党建、中国共产党与中国现代化。2008年1月，在2007年度、2006-2007年度教学质量评优活动中，被评为教学质量优秀奖 。2008年5月，被评为广东省党的建设学会先进个人 。2009年5月，被评为2006级中函本科韶关市直班先进面授教师。【科研成果】 参与国家社科基金项目“十八大以来中国特色社会主义政党制度的创新发展及基本经验研究”。在《探索》、《贵州社会科学》、《南京政治学院学报》、《中南大学学报（社会科学版）》、《宁夏社会科学》、《毛泽东思想研究》等CSSCI来源期刊及北大核心期刊发表论文10余篇。',
                    honorTeacherName:'王超博士'
                },
                {
                    honorTeacherImg:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fseopic.699pic.com%2Fphoto_origin%2F40076%2F4217.png%21bd800&refer=http%3A%2F%2Fseopic.699pic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1663934781&t=66917b2ee2d604c5eab2f53272b51eba',
                    honorTeacherIntroduce:'2009年毕业于深圳大学，企业管理硕士研究生，讲师，中级经济师，企业管理咨询师； 获奖： 1、在社科奖第五届全国高校市场营销大赛中荣获二等奖； 2、在社科奖第六届全国高校市场营销大赛中荣获一等奖； 论文： 1、C2C网络购物买方初始信任的建立分析，2013年5月，中国商界 2、自我概念--礼品形象一致性对消费者礼品购买意愿的影响，2015年1月，管理学家 教材： 市场调查与预测，副主编。',
                    honorTeacherName:'李元硕士'
                }

            ]


        }
    },

    
}
</script>