<template>
  <div id="app">
    <headerTop></headerTop>
    <router-view/>
    <footerDown></footerDown>
  </div>
</template>

<script>
import headerTop from './components/headerTop.vue'
import footerDown from './components/footerDown'
export default {
  components: { headerTop ,footerDown},
  name: 'App',
  component:{
        headerTop,
        footerDown
    }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}

#app{
  /* width:calc(100% - 100px); */
  height: auto;
}

.el-row{
      /* width: 1500px; */
}

/* 1600*1200 */
@media screen and (max-width: 1600px) {
  .el-row{
        width: 1520px;
  }

}

.content{
    /* position: relative;
    width: 100%;
    height: 1000px;
    bottom: 85px; */
    /* border: 1px solid red; */
    overflow: hidden;
}
</style>
