<template>
  <el-row :gutter="24" class="header">
    <div class="header-content">
      <div class="header-content-left">
        <a href="#">
          <!-- <img src="http://www.mtxcedu.com/baks/static/picture/logo.svg" alt=""> -->
        </a>
      </div>
        <div class="header-content-right">
          <ul>
            <li v-for="(item, index) of menuList" :id="(index+1)" :key="index" @click="handerMenuItemClick(item)" :class="{'active': item.path==paths}">{{item.name}}</li>
          </ul>
        </div>
       
    </div>
      
  </el-row>
</template>

<style scoped>
li{
  cursor:pointer;
}
</style>

<script>
export default {
  name: 'headerTop',
  data () {
    return {
      menuList: [{
        name: '关于星辰互联',
        id: 1,
        routeName: '/',
        path: '/index'
      }, {
        name: '企业使命',
        id: 2,
        routeName: 'company',
        path: '/company'
      },{
        name: '正规单位',
        id: 3,
        routeName: 'introduce',
        path: '/introduce'
      },{
        name: '服务至上',
        id: 4,
        routeName: 'corporate',
        path: '/corporate'
      },{
        name: '发展历程',
        id: 5,
        routeName: 'history',
        path: '/history'
      },{
        name: '投诉建议',
        id: 8,
        routeName: 'complaint',
        path:'/complaint'
      }
      ],
      paths: '',
      menuActiveIndex: 1
    }
  },
  methods: {
    handerMenuItemClick(item) {
      // console.log("item数据",item)
      this.menuActiveIndex = item.id;
      this.$router.push({name: item.routeName})
    },

  },
  // 监听路由
  watch:{
  $route(to,from){
    this.paths = to.path;
  }
},
  


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header{
  position: fixed;
  width: 1600px;
  height: 80px;
  top: 20px;
  left: 50%;
  margin: 0 auto;
  transform: translateX(-50%);
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  /* box-shadow: 0 0 20px #e1f5ff; */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.header-content{
  position: relative;
  line-height: 80px;
}

.header-content-left{
  float: left;
}

.header-content-right{
  float: right;
  margin-right: 20px;
}

a{
  text-decoration: none;
  color: #333333;
}

a > img {
    border: none;
    vertical-align: top;
    float: left;
    margin-left: 35px;
    margin-top: 20px;
}

ul > li {
  /* 取消原点 */
  list-style: none;
  float: left;
  margin-left: 20px;
  font-family:  "PingFang SC";
  font-size: 18px;
  color: #4e3d3d;
  cursor:pointer;
}
li.active {
  color: #409EFF;
  border-bottom: 2px solid #409EFF;
  font-size: 20px;
  font-family: Helvetica Neue;
}


/* 1600*1200 */
@media screen and (max-width: 1600px) {
  .header{
    position: fixed;
    width: 1500px;
    height: 80px;
    top: 20px;
    left: 50%;
    margin: 0 auto;
    transform: translateX(-50%);
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 20px #e1f5ff;
    z-index: 10;
  }

}

/* 1366*768 */
@media screen and (max-width: 1366px) {
  .header{
    position: fixed;
    width: 1200px;
    height: 80px;
    top: 20px;
    left: 50%;
    margin: 0 auto;
    transform: translateX(-50%);
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 20px #e1f5ff;
    z-index: 10;
  }

  .education-field > .education-field-content{
        position: relative;
        margin: 0 auto;
        width: 1200px;
  }

  .education-field .education-field-content ul .liStyle{
        border-width: 1px;
        border-color: rgb(53, 53, 53);
        border-style: solid;
        /* width: 16.1%; */
        width: 210px;
        height: 196px;
        margin-left: 30px;
  }
}

</style>
