/* eslint-disable no-undef */
import Vue from 'vue'
import VueRouter from 'vue-router'

import index from '../view/index'
Vue.use(VueRouter)





const routes = [
  {
    path: '/',
    name: '/',
    meta:{
      title: '首页',
      id:1
    },
    redirect : index
  },
  {
    path : '/index',
    name: 'index',
    meta:{
      title: '首页',
      id:1

    },
    component: () => import('../view/index')
  },
  {
    path: '/company',
    name: 'company',
    meta:{
      title: '企业使命',
      id:2
    },
    component: () => import('../view/company')
  },{
    path: '/introduce',
    name: 'introduce',
    meta:{
      title: '正规单位',
      id:3
    },
    component: () => import('../view/introduce')
  },{
    path: '/corporate',
    name: 'corporate',
    meta:{
      title: '服务至上',
      id:4
    },
    component: () => import('../view/corporate')
  },{
    path: '/history',
    meta:{
      title: '发展历程',
      id:5
    },
    name: 'history',
    component: () => import('../view/history')
  },
  // {
  //   path: '/news',
  //   name: 'news',
  //   meta:{
  //     title: '新闻中心',
  //     id:6
  //   },
  //   component: () => import('../view/news')
  // },
  {
    path: '/complaint',
    name: 'complaint',
    meta:{
      title: '投诉建议',
      id:8
    },
    component: () => import('../view/complaint')
  },{
    path: '/article',
    name: 'article',
    meta:{
      title: '文章中心'
    },
    component: () => import('../view/article')
  },{
    path: '/md',
    name: 'md',
    component: () => import('../view/md')
  }
];


//创建一个路由器对象，该对象用于管理当前项目中的所有路由
const router = new VueRouter({
  //配置具体的路由信息
  routes
})
  
//路由前置守卫
router.beforeEach((to,from,next)=>{
  document.title = to.meta.title  
  next();
})

//路由后置守卫
router.afterEach((to,from)=>{
  // document.getElementById(to.meta.id).style.color = "black"
 

})
	


//默认导出router对象
export default router
