<template>
    <el-row :gutter="24" class="footer">
    <div class="footer-box3">
        <div>
            <!-- 遍历数据 -->
            <dl class="us" :key="index" v-for="(item,index) in usList">
                <dt>
                    <a>{{ item.title }}</a>
                </dt>
                <dd :key="indexs" v-for="(items,indexs) in item.ddList">
                    <el-link @click="handerMenuItemClick(items.a1)">{{ items.a1title }}</el-link>
                </dd>
            </dl>
            <!-- 联系我们 -->
            <!-- <dl class="center" :key="index" v-for="(item,index) in contactUsList">
                <dt>{{ item.dt }}</dt>
                <dd :key="indexs" v-for="(items,indexs) in item.ddList"> {{ items.dd }} </dd>
            </dl> -->
            <!-- <dl class="dl1">
                <dt class="dt2">
                    <img src="https://s4.ax1x.com/2022/02/11/HakhWT.png" alt="袋鼠课堂APP下载">
                </dt>
                <dd>
                    星辰互联学堂
                    <br>APP下载
                </dd>
            </dl>
            <dl class="dl1">
                <dt class="dt2">
                    <img src="http://www.mtxcedu.com/baks/static/image/SN.jpg" alt="星辰教育微信订阅号">
                </dt>
                <dd>
                    星辰互联教育
                    <br>微信订阅号
                </dd>
            </dl>
            <dl class="dl1">
                <dt class="dt2">
                    <img src="http://www.mtxcedu.com/baks/static/image/ServiceN.jpg" alt="星辰教育微信服务号">
                </dt>
                <dd>
                    星辰互联教育
                    <br>微信服务号
                </dd>
            </dl> -->
        </div>
    </div>
    <!-- <p>
        友情链接:<a target="view_window" href="http://www.mtxcedu.com">星辰互联教育</a>
    </p>
    <p> Copyright © All Rights Reserved. 版权所有：深圳市满天星辰教育有限公司 备案号：
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="view_window" rel="nofollow">
            粤ICP备2020106181号
        </a>
    </p> -->
</el-row>
</template>

<style scoped>
.footer .footer-box3>div .center {
	text-align: left;
	border-left: 1px solid rgba(149 189 255 / 20%);
	border-right: 1px solid rgba(149 189 255 / 20%);
	padding: 0 60px 0 80px
}

.footer .footer-box3>div .center dd {
	color: #818181;
	font-size: 14px;
	margin: 4px 0
}

.footer .footer-box3>div .center .center_black {
	margin-top: 25px
}

.footer {
    /* display: list-item; */
	/* padding: 44px 0 25px; */
    /* width: calc(1920px - 480px); */
    zoom: 1;
    padding: 25px;
	/* background: #0a142d; */
    background: #051848;
	text-align: center;
	color: #fff
}

.footer .footer-box3 {
	width: 1385px;
    /* margin: auto auto 40px */
	margin: auto;
}

.footer .footer-box3>div {
	display: flex;
	/* flex-direction: row; */
	justify-content: space-around
}

.footer .footer-box3>div dl.us {
	text-align: left;
	cursor: pointer
}

.footer .footer-box3>div dl.dl1 {
	margin-top: 0
}

.footer .footer-box3>div dl.dl1>dd {
	color: #fff
}


.footer .footer-box3>div dl dt {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 26px
}

.footer .footer-box3>div dl dt a {
	color: #fff
}

.footer .footer-box3>div dl dt.dt1>span {
	display: block;
	width: 58px;
	height: 58px;
	line-height: 58px;
	background: rgba(158,164,174,.5);
	border-radius: 50%;
	margin: 0 auto
}

.footer .footer-box3>div dl dt.dt1>span>img {
	width: 28px;
	height: 28px;
	vertical-align: -8px
}

.footer .footer-box3>div dl dt.dt2 {
	margin-bottom: 8px
}

.footer .footer-box3>div dl dt.dt2>img {
	width: 84px;
	height: 84px
}

.footer .footer-box3>div dl dd {
	font-size: 16px;
	margin: 15px 0;
	color: #86898d
}

.footer .footer-box3>div dl dd.dd1 {
	font-weight: 700;
	font-size: 24px;
	margin: 14px 0 9px
}

.footer .footer-box3>div dl dd.dd3 {
	font-size: 12px;
	margin-top: 0
}

.footer .footer-box3>div dl dd a {
	color: #86898d
}

.footer .footer-box3>div dl dd a:hover {
	color: #fff
}

.footer>p {
	width: 1300px;
	margin: auto;
	text-align: left;
	color: #818181;
	font-size: 14px
}

.footer>p a {
	color: #818181
}

.footer>p a:hover {
	color: #fff
}

.footer>p:first-of-type {
	border-bottom: 1px solid rgba(149 189 255 / 20%);
	padding-bottom: 20px;
	margin-bottom: 20px;
	color: #818181
}

.footer>p:first-of-type a {
	margin-right: 24px
}

.footer>p:first-of-type a:first-of-type {
	margin-left: 16px
}

.footer>p:last-of-type {
	font-size: 13px
}
</style>

<script>
export default {
    name : 'footerDown',
    data(){
        return{
            usList:[
                {
                    a:'#',
                    title: '星辰互联',
                    ddList:[
                        {
                            a1:'index',
                            a1title:'关于星辰互联',                    
                        },{
                            a1:'company',
                            a1title:'企业使命',
                        },{
                            a1:'history',
                            a1title:'发展史',
                        }
                    ]
                   
                },
                {
                    a:'#',
                    title: '综合服务',
                    ddList:[
                        {
                            a1:'corporate',
                            a1title:'学生风采'
                        }
                    ]
                },
                {
                    a:'#',
                    title: '投诉建议',
                    ddList:[
                        {
                            a1:'complaint',
                            a1title:'投诉建议',
                        }
                    ]
                }
            ],
            contactUsList:[
                {
                    dt:'联系我们',
                    ddList:[
                        {
                            dd: '企业电话：0755-21047464'
                        },{
                            dd:'周一至周日 9:00-23:00'
                        },{
                            dd:'品牌项目合作及咨询'
                        },{
                            dd:'电话：17603088853'
                        },{
                            dd:'邮箱：1458588498@qq.com'
                        },{
                            dd:'周一至周五 9:45-18:45'
                        }
                    ]
                }
            ],
        }
    },
    methods: {
    handerMenuItemClick(item) {
        // console.log("数据",item)
      this.$router.push({name: item})
    }
  },
}
</script>