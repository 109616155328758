import Vue from 'vue'
import App from './App.vue'
import router from './router/index'

// 导入Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
// 导入Swiper css样式
import 'swiper/css/swiper.css'

// 导入Element UI
import ElementUI from 'element-ui';
// 导入Element css样式
import 'element-ui/lib/theme-chalk/index.css';

// 全局注册Jquery
// eslint-disable-next-line no-unused-vars
import $ from 'jquery'

// 全局注册Swiper
Vue.use(VueAwesomeSwiper)
// 全局注册Element ui
Vue.use(ElementUI);
Vue.config.productionTip = true

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
